import React, { useState, useEffect } from "react";
import './UploadSection.css';
import { useNavigate } from "react-router-dom";
import { FaCloudUploadAlt, FaTimes } from 'react-icons/fa';
import Header from './Header';
import config from './config.json';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import logo from './logo.svg';
import Bot from './bot';
import Modal from './Modal';

function UploadSection({ parseId, name, setParseId, setFolder, setFileLength, jobRole, setIsRegisterModalOpen, isRegisterModalOpen, processStatus, setUploadedFiles, setDriveLink, setIsLoggedIn, setUsername, setAccessKey, uploadedFiles, accessKey, remainingCredits, setRemainingCredits, userId }) {
  const [driveLink, setDriveLinkLocal] = useState("");
  const [isLoggedIn, setIsLoggedInLocal] = useState(false);
  const [username, setUsernameLocal] = useState(""); // For login
  const [files, setFiles] = useState([]);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [hasLoggedOut, setHasLoggedOut] = useState(false);
  const navigate = useNavigate();
  const [dotCount, setDotCount] = useState(0);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [showForensicsModal, setShowForensicsModal] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [forensicsApplied, setForensicsApplied] = useState(false); // Track if forensics is applied
  const [runId, setRunID] = useState("");
  const [fileCount, setFileCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleModalClose = () => setIsModalOpen(false);

  const steps = ["Select Job Role", "Upload Resumes", "Choose Validation"]
 
  const messages = forensicsApplied
    ? [
        { text: "Uploading resumes", duration: 2000 },
        { text: "Applying resume forensics", duration: 5000 },
        { text: "Processing", duration: 3000 },
        { text: "Job matching", duration: 4000 },
      ]
    : [
        { text: "Uploading resumes", duration: 2000 },
        { text: "Processing", duration: 3000 },
        { text: "Job matching", duration: 4000 },
      ];

  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [customFolderName, setCustomFolderName] = useState("");

  const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedAccessToken = localStorage.getItem("access_token");

    if (storedEmail && storedAccessToken) {
      fetchFolders(storedEmail, storedAccessToken);
    }

    if (storedEmail && storedAccessToken) {
      setIsLoggedIn(true);
      setIsLoggedInLocal(true);
      setUsernameLocal(storedEmail);
      setAccessKey(storedAccessToken); // Set access token if available
    }

    //console.log(jobRole);
  }, [setIsLoggedIn, setAccessKey]);

  useEffect(() => {
    const storedFiles = JSON.parse(localStorage.getItem('uploadedFiles'));
    const storedDriveLink = localStorage.getItem('driveLink');

    if (storedFiles) {
      setFiles(storedFiles.map((fileName) => ({ name: fileName })));
      setUploadedFiles(storedFiles.map((fileName) => ({ name: fileName })));
    }

    if (storedDriveLink) {
      setDriveLinkLocal(storedDriveLink);
      setDriveLink(storedDriveLink);
    }
  }, [setUploadedFiles, setDriveLink]);

  useEffect(() => {
    if (runId) {
      // Navigate only after runId is successfully set
      navigate(`/results?run_id=${runId}&folder_name=${selectedFolder}&job_role=${jobRole.jobTitle}`);
    }
  }, [runId, navigate, selectedFolder, jobRole]);
  
  useEffect(() => {
    if (isAnimating) {
      let currentIndex = 0;
      const displayMessages = () => {
        if (currentIndex < messages.length) {
          setCurrentMessageIndex(currentIndex);
          currentIndex += 1;
          setTimeout(displayMessages, messages[currentIndex - 1].duration + 1000);
        }
      };
  
      const dotInterval = setInterval(() => {
        setDotCount((prevCount) => (prevCount + 1) % 4);
      }, 500);
  
      displayMessages();
  
      return () => clearInterval(dotInterval);
    }
  }, [isAnimating]);
  

  const triggerPopup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupMessage("");
  };

  const handleOptionsClick = () => {
    if (selectedFolder) {
      const selectedFolderObj = folders.find(folder => folder.folder_name === selectedFolder);
      if (selectedFolderObj) {
        setParseId(selectedFolderObj.parse_id);
        setFolder(selectedFolder);
        navigate("/forensics");
      } else {
        console.error("Selected folder not found in folders array");
      }
    } else {
      triggerPopup("Please select a folder before proceeding.");
    }
  };

  // Fetch the number of files in the selected folder
  const fetchFileCount = async (folderName) => {
    const storedEmail = localStorage.getItem("email");
    if (!folderName || !storedEmail) {
      triggerPopup("Please select a folder.");
      return 0;
    }

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/retrieve-files-in-folder`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ folder_name: folderName, email: storedEmail }),
      });

      const data = await response.json();
      if (response.ok && data.data && Array.isArray(data.data.files)) {
        const numFiles = data.data.files.length;
        setFileCount(numFiles); // Store the number of files
        return numFiles;
      } else {
        console.error("Invalid data format:", data);
        return 0;
      }
    } catch (error) {
      console.error("Error fetching files:", error);
      return 0;
    }
  };

  // Check user credit status before API request
  const checkCreditStatus = async (totalApiHits) => {
    try {
      const response = await fetch(`${config.AUTH_BASE_URL}/api/credit/check-status`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          product_name: "MAYAMAYA_LITE",
          total_api_hits: totalApiHits,
        }),
      });

      const creditData = await response.json();
      if (creditData.msg && creditData.msg.includes("Bad Authorization header") && !hasLoggedOut) {
        handleLogout();
        setHasLoggedOut(true);
      }
      if (response.ok && creditData.status === 200) {
        setRemainingCredits(creditData.data.credits);
        return creditData.data.credits;
      } else {
        triggerPopup(creditData.message || "Error checking credit status");
        return false;
      }
    } catch (error) {
      console.error("Error checking credit status:", error);
      return false;
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername(""); // Clear username on logout
    setAccessKey(""); // Clear access key on logout
    localStorage.removeItem("access_token"); // Remove access token from localStorage
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles"); // Remove uploaded files from localStorage
    localStorage.removeItem("driveLink");
    window.location.href = "/"; // Redirect to homepage
  };

  const handleForensicsChoice = async (applyForensics) => {
    setForensicsApplied(applyForensics); // Set if forensics is applied
    setShowForensicsModal(false);
    setIsAnimating(true);

    // Prepare and send data to the backend
    const storedEmail = localStorage.getItem("email");
    const folderName = selectedFolder || customFolderName;
    setFolder(folderName);

    const numFiles = await fetchFileCount(folderName);

    if (numFiles === 0) {
      triggerPopup("No files found in the folder.");
      return;
    }

    // Check if credits are sufficient based on the number of files
    const availableCredits = await checkCreditStatus(numFiles);

    if (availableCredits < numFiles) {
      triggerPopup("Insufficient credits. Please recharge.");
      return;
    }

    const payload = {
      email: storedEmail,
      job_id: jobRole._id,
      folder_name: folderName,
      forensics: applyForensics.toString(),
      jdm: true,
      user_id: userId,
    };

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/jdm-forensics`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data.data.run_id);        
        setRunID(data.data.run_id)
        setRemainingCredits(availableCredits - numFiles);
        
      } else {
        console.error("Error sending data to the backend.");
      }
    } catch (error) {
      console.error("Error in API request:", error);
    }
  };

  const fetchFolders = async (email, accessKey) => {
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/retrieve-folders`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (data && data.data && Array.isArray(data.data.folders)) {
        const completedFolders = data.data.folders.filter(folder => folder.resume_parser_status === "completed");
        setFolders(completedFolders); // Store the full folder objects
      } else {
        console.error("Invalid data format:", data);
        setFolders([]);
      }
    } catch (error) {
      console.error("Error fetching folders:", error);
      setFolders([]);
    }
  };

  useEffect(() => {
    //console.log("Current Uploaded Files:", uploadedFiles.length);
  }, [uploadedFiles]);

  const handleFileSelect = (event) => {
    const newFiles = Array.from(event.target.files); // Get selected files as an array
    const totalFiles = files.length + newFiles.length;

    // Check if the total number of files exceeds the limit
    if (totalFiles > 250) {
      triggerPopup("File limit exceeded. You can only upload up to 250 files.");
      event.target.value = null;
      return; // Prevent further processing if limit exceeded
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]); // Append new files to selectedFiles
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles])
    
};

const handleRemoveFile = (index) => {
  setSelectedFiles((prevFiles) => {
    const updatedFiles = [...prevFiles];
    updatedFiles.splice(index, 1);
    return updatedFiles;
  });
  setUploadedFiles((prevFiles) => {
    const updatedFiles = [...prevFiles];
    updatedFiles.splice(index, 1);
    return updatedFiles;
  });
};

const handleFileUpload = async () => {
  const storedEmail = localStorage.getItem("email");
  const accessKey = localStorage.getItem("access_token");

  if (!storedEmail || !accessKey) {
    triggerPopup("Please log in to upload files.");
    return;
  }

  const folderName = selectedFolder || customFolderName;
  if (!folderName) {
    triggerPopup("Please select or create a folder.");
    return;
  }

  setFolder(folderName);

  const formData = new FormData();
  formData.append("email", storedEmail);
  formData.append("folder_name", folderName);

  // Check if either files or drive link is available
  if (selectedFiles.length > 0) {
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });
  } else if (driveLink) {
    formData.append("link", driveLink);
  } else {
    triggerPopup("Please either select files or provide a Google Drive link.");
    return;
  }

  try {
    const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/upload-batch`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessKey}`,
      },
      body: formData,
    });

    const result = await response.json();

      if (response.ok && result.success) {
        triggerPopup("Upload successful!");
        const uploadedFolder = customFolderName || selectedFolder;
        setSelectedFolder(uploadedFolder);
        await fetchFolders(storedEmail, accessKey);
        const parseId = result.data.parse_id;
      const numFiles = result.data.num_files;
      setFileLength(numFiles);

      // Store parse_id and num_files in state or pass them to the next component
      setParseId(parseId);
      setFileLength(numFiles);
        if (selectedFiles.length > 0 || driveLink) {
          navigate("/forensics");
        }
      } else if (result.status === 400 && result.message === "Folder name already exists") {
        triggerPopup("Folder name already exists. Please choose a different name.");
      } else {
        triggerPopup("Upload failed. Please try again.");
      }

  } catch (error) {
    console.error("Error uploading files:", error);
    triggerPopup("Error during upload.");
  }
};


const handleLogo = () => {
  navigate("/");
}

const handleCancelClick = () => {
  navigate("/");
}

const handleBackClick = () => {
  navigate("/job-description");
}

const handleNext = () => {
  setShowForensicsModal(true)
  //console.log(jobRole);
  
}

const currentStep = 2;

  return (
    <div>
      <Header
        setIsLoggedIn={setIsLoggedIn}
        setUsername={setUsername}
        setAccessKey={setAccessKey}
        remainingCredits={remainingCredits}
        setIsRegisterModalOpen={setIsRegisterModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        isRegisterModalOpen={isRegisterModalOpen}
        name={name}
      />
    <Modal isOpen={isModalOpen} onClose={handleModalClose}>
    <div>
      <div className='stepper-header'><p>SHORTLIST CANDIDATES</p></div>
      {!showForensicsModal && (<>
      {/* Step Progress Bar */}
      <div className="step-progress-bar">
        <div style={{display: "flex", justifyContent: "space-between", textAlign: "center"}}><p style={{width: "33.33%", color: "#777"}}>1. Create/Choose Job Role</p><p style={{width: "33.33%", color: "#fff"}}>2. Upload Resumes</p><p style={{width: "33.33%", color: "#777"}}>3. Create Folder</p></div>
        <div className="progress-line" style={{margin: '10px 0'}}>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#777" }}></div>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#fff" }}></div>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#777" }}></div>
        </div>
      </div>
      <div className="upload-section-title">
        {showPopup && (
          <div>
            <div className="overlay" onClick={handleClosePopup}></div>
            <div className="popup">
              <button className="close-btn" onClick={handleClosePopup}>×</button>
              {popupMessage}
            </div>
          </div>
        )}
      </div>

      {isAnimating ? (
        <div className="credits-container">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`credits ${currentMessageIndex === index ? 'fade-in' : 'fade-out'}`}
            >
              {message.text}
              {currentMessageIndex === index && (
                <span className="loader-dots">
                  {'.'.repeat(dotCount)} {/* Display dots based on dotCount only for current message */}
                </span>
              )}
            </div>
          ))}
        </div>
      ) : (
        <section className="upload-section">
          <div className="upload-area">
          {folders.length > 0 && (
            <>
            <h2>Select Resumes From Existing Folders</h2>

            <div className="folder-selection" style={{width: "100%"}}>
            <select
                  id="folder-select"
                  className="options-popup-dropdown"
                  value={selectedFolder}
                  onChange={(e) => setSelectedFolder(e.target.value)}
                >
                  <option value="" disabled>Select a folder</option>
                  {folders.map((folder, index) => (
                    <option key={index} value={folder.folder_name}>{folder.folder_name}</option>
                  ))}
                </select>

            </div>

            <div className="upload-button-container">
              <button className='proceed-button' onClick={handleOptionsClick} style={{paddingLeft: "18px"}}>Next
              </button>
            </div></>
          )}

            <h2 style={{marginTop: "30px"}}>{folders.length === 0 ? "" : "Or Upload Resumes"}</h2>

            <label style={{color: "#333", textAlign: "left", width: "100%"}}>Drive Link</label>
            <input
              type="url"
              id="drive-link"
              className="drive-link-input"
              value={driveLink}
              onChange={(e) => setDriveLinkLocal(e.target.value)}
              placeholder="Enter Google Drive link"
            />

            {/* Drag-drop box made clickable */}
            <label style={{color: "#333", textAlign: "center", width: "100%"}}>Or</label>
            <div
              className="drag-drop-box"
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                handleFileSelect(e); // Call handleFileSelect to handle files from drag-and-drop
              }}
              onClick={() => document.getElementById('file-upload').click()}
            >
              <p><p><FaCloudUploadAlt className="upload-icon" /></p>Drag & drop files to upload resumes/ <span style={{color: "#672024", textDecoration: "underline"}}>Choose Files</span>
              <p className="file-types">Supported Format <span style={{padding: "1px 10px", borderRadius: "15px", border: "1px solid #ccc", background: "#f7f7f7", fontSize: "0.8rem"}}>PDF</span></p></p>
            </div>

            <input
              type="file"
              id="file-upload"
              style={{ display: "none" }}
              accept=".pdf"
              multiple
              onChange={(e) => handleFileSelect(e)}
            />

            

          <div className="upload-file-list">
            {selectedFiles.length > 0 ? (
              <>
              <h4>Uploaded Resumes ({selectedFiles.length})</h4>
              <ul>
              {selectedFiles.map((file, index) => (
                <li key={index}>{file.name}<button
                className="remove-file-button"
                onClick={() => handleRemoveFile(index)}
              >
                <FaTimes />
              </button></li> // Accessing the name property correctly
              ))}
            </ul>
            </>
            ) : (
              <></>
            )}
          </div>
           
            
          </div>
          <div className="jd-button-container2" style={{paddingBottom: "30px"}}>
              <button className="jr-cancel-button" onClick={handleCancelClick}>
                        Cancel
                      </button>
              <button className="proceed-button" onClick={handleNext}>
              Next
              </button>
              </div>

        </section> 
      )}
      </>)}
       {showForensicsModal && (<>
        <div className="step-progress-bar">
        <div style={{display: "flex", justifyContent: "space-between", textAlign: "center"}}><p style={{width: "33.33%", color: "#777"}}>1. Create/Choose Job Role</p><p style={{width: "33.33%", color: "#777"}}>2. Upload Resumes</p><p style={{width: "33.33%", color: "#fff"}}>3. Create Folder</p></div>
        <div className="progress-line" style={{margin: '10px 0'}}>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#777" }}></div>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#777" }}></div>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#fff" }}></div>
        </div>
      </div>
            {/* Custom Folder Name Input */}
            <section className="upload-section">
          <div className="upload-area">
            <label style={{color: "#333", textAlign: "left", width: "100%"}}>Create Folder</label>
            <div className="custom-folder-input" style={{width: "100%"}}>
              <input
                type="text"
                id="custom-folder-name"
                className="drive-link-input"
                value={customFolderName}
                onChange={(e) => setCustomFolderName(e.target.value)}
                placeholder="Enter folder name"
              />
            </div>
            <p style={{color: "#555", marginBottom: "15px", fontSize: "0.9rem", width: "100%"}}>{selectedFiles.length} resumes are being moved to this folder</p>

            <div className="upload-file-list2">
            {selectedFiles.length > 0 ? (
              <>
              <h4>Uploaded Resumes ({selectedFiles.length})</h4>
              <ul>
              {selectedFiles.map((file, index) => (
                <li key={index}>{file.name}<button
                className="remove-file-button"
                onClick={() => handleRemoveFile(index)}
              >
                <FaTimes />
              </button></li> // Accessing the name property correctly
              ))}
            </ul>
            </>
            ) : (
              <></>
            )}
          </div>
          </div>
          <div className="jd-button-container2" style={{paddingBottom: "30px"}}>
                <button className="jr-cancel-button" onClick={handleBackClick}>
                          Back
                        </button>
                <button className="proceed-button" onClick={handleFileUpload}>
                Upload and Next
                </button>
                </div>
          </section>
            </>)}
      <Bot onLoad={handleBotLoad} />
  </div></Modal>
  </div>
  );
}

export default UploadSection;
