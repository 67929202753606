import React, { useEffect } from 'react';

const Bot = ({ onLoad }) => {
  useEffect(() => {
    // Check if the script is already loaded
    if (document.getElementById('hs-script-loader')) return;

    // Create and append the HubSpot script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js.hs-scripts.com/19983043.js';  // Replace with your actual HubSpot account ID

    script.onload = () => {
      //console.log('HubSpot script loaded');
      if (onLoad) onLoad(window.HubSpot);  // Call the onLoad function passed as a prop
    };

    script.onerror = (error) => {
      console.error('Error loading HubSpot script:', error);
    };

    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [onLoad]);

  return null;  // No UI rendering, only script loading
};

export default Bot;
