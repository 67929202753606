import React, { useState, useEffect } from 'react';
import Header from './Header'; // Adjust the path as needed
import './Pricing.css'; // Import the CSS file
import Bot from './bot';
import config from './config.json';

function Pricing({ name, isModalOpen, isRegisterModalOpen, setIsRegisterModalOpen, setIsModalOpen, setIsLoggedIn, setUsername, setAccessKey, remainingCredits, subscriptionType }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false); // State for subscription status
  const [subscriptionDetails, setSubscriptionDetails] = useState(null); // Subscription info state
  const [isLoggedIn, setIsLoggedInState] = useState(false);
  const [activeTab, setActiveTab] = useState('pricing');
  const [billingHistory, setBillingHistory] = useState([]);
  const [filteredBillingHistory, setFilteredBillingHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };

  useEffect(() => {
    // Add Stripe script dynamically
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  // Simulate checking subscription status (replace with real API call)
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      setIsLoggedInState(true);
    } else {
      setIsLoggedInState(false);
    }
    if (subscriptionType === 'paid') {
      const fetchSubscriptionDetails = async () => {
        const response = {
          subscribed: true,
          planName: '3000 Credits', // Updated plan name
          amount: 19.99,
          credits: 3000, // Updated credits
        };
        console.log('Subscription Response:', response);
        if (response.subscribed) {
          setIsSubscribed(true);
          setSubscriptionDetails(response);
        } else {
          setIsSubscribed(false);
          setSubscriptionDetails(null);
        }
      };
      fetchSubscriptionDetails();
    } else {
      setIsSubscribed(false);
      setSubscriptionDetails(null);
    }
  }, [subscriptionType]);

  useEffect(() => {
    if (activeTab === 'billing' && isLoggedIn) {
      fetchBillingHistory();
    }
  }, [activeTab, isLoggedIn]);

  const fetchBillingHistory = async () => {
    setIsLoading(true);
    setError(null);
    const storedEmail = localStorage.getItem("email");
    const accessToken = localStorage.getItem("access_token");

    if (!storedEmail || !accessToken) {
      setError("User information not found. Please log in again.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${config.AUTH_BASE_URL}/api/user/get-purchase-history`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: storedEmail,
          page_number: 1,
          product_code: "MAYAMAYA_LITE"
        }),
      });

      const result = await response.json();

      if (result.status === 200) {
        const newHistory = Object.values(result.data.purchase_history).map(transaction => ({
          payment_date: transaction.payment_date,
          amount: transaction.amount,
          credits_received: transaction.credits_received
        }));
        setBillingHistory(prevHistory => [...prevHistory, ...newHistory]);
        setFilteredBillingHistory(prevHistory => [...prevHistory, ...newHistory]);
      } else {
        setError(result.message || "Failed to fetch billing history");
      }
    } catch (err) {
      setError("An error occurred while fetching billing history");
    } finally {
      setIsLoading(false);
    }
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleDateFilter = () => {
    const filtered = billingHistory.filter(transaction => {
      const transactionDate = new Date(transaction.payment_date);
      const from = fromDate ? new Date(fromDate) : new Date(0);
      const to = toDate ? new Date(toDate) : new Date();
      return transactionDate >= from && transactionDate <= to;
    });
    setFilteredBillingHistory(filtered);
  };

  if (!isLoggedIn) {
    return <div className="pricing-container">
    <Header
      setIsLoggedIn={setIsLoggedIn}
      setUsername={setUsername}
      setAccessKey={setAccessKey}
      remainingCredits={remainingCredits}
      setIsRegisterModalOpen={setIsRegisterModalOpen}
      setIsModalOpen={setIsModalOpen}
      isModalOpen={isModalOpen}
      isRegisterModalOpen={isRegisterModalOpen}
    /><div style={{minHeight: "calc( 100vh - 340px )", marginLeft: "100px"}}><p>Please log in to view this page.</p>
    </div>
    <footer className="footer">
        <p>MayaMaya Lite - A MayaMaya Product</p>
      </footer>
    </div>
  }

  return (
    <div className="pricing-container">
      <Header
        name={name}
        setIsLoggedIn={setIsLoggedIn}
        setUsername={setUsername}
        setAccessKey={setAccessKey}
        remainingCredits={remainingCredits}
        setIsRegisterModalOpen={setIsRegisterModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        isRegisterModalOpen={isRegisterModalOpen}
      />

        <div className="pricing-tabs-container">
          <button
            className={activeTab === 'pricing' ? 'active-tab' : ''}
            onClick={() => setActiveTab('pricing')}
          >
            Pricing Plan

              
          </button>
          <button
            className={activeTab === 'billing' ? 'active-tab' : ''}
            onClick={() => setActiveTab('billing')}
          >
            Billing History

          </button>
          
        </div>
      <div className="pricing-content">
      {activeTab === 'pricing' && (
          <>
        <div className="pricing-header">
        
          <div className="total-credits">
            <p>Credits: <span>{remainingCredits}</span></p>
          </div>
          <h1 className="pricing-title">
            Buy More Credits <span>Check More Resumes</span>
          </h1>
          <p className="pricing-description">
            Easily purchase credits to access premium features and unlock new opportunities. Our flexible credit
            packages are designed to fit your needs.
          </p>
        </div>

        {/* Pricing Cards Section */}
        <div className="pricing-cards">
          <div className="pricing-card">
            <div className="card-content">
              <h3 className="card-title">
                <span style={{ textDecoration: 'line-through', color: '#e3e3e3', fontSize: "1.2rem" }}>2500 Credits</span><br />
                <span style={{ marginLeft: '0px', color: '#fff' }}>3000 Credits</span>
              </h3>
              <p className="card-description">Our most popular credit package for advanced features.</p>
              <div className="card-price">$29.99</div>
              <stripe-buy-button
                buy-button-id="buy_btn_1QKlDeEFgkQkp9XBDnKhJgYl"
                publishable-key="pk_live_51JUlKeEFgkQkp9XBlmsS9rlqGHEzVZJfUVRZKVz7fxiRIzBxSX9dcE7AL3kvN1gTSJHgWWAsKhYfL8jMpM6Ogtlf00tWqkLoPR"
              ></stripe-buy-button>
            </div>
            <div className="card-footer">
            </div>
          </div>
        </div>

        <div className="how-it-works">
          <h2 className="section-title-works">How It <span>Works</span></h2>
          <div className="steps">
            <div className="step">
              <h3 className="step-title">1. Select Package</h3>
              <p className="step-description">Choose the credit package that best fits your needs.</p>
            </div>
            <div className="step">
              <h3 className="step-title">2. Secure Checkout</h3>
              <p className="step-description">Proceed to a secure Stripe checkout to complete your purchase.</p>
            </div>
            <div className="step">
              <h3 className="step-title">3. Start Using</h3>
              <p className="step-description">Your credits will be instantly available to use.</p>
            </div>
          </div>
        </div>

        

        <div className="faq">
          <h2 className="section-title">Frequently Asked <span>Questions</span></h2>
          <div className="accordion">
            {faqData.map((item, index) => (
              <div key={index} className="accordion-item">
                <button
                  className="accordion-trigger"
                  onClick={() => toggleAccordion(index)}
                >
                  {item.question}
                </button>
                {activeIndex === index && (
                  <div className="accordion-content">
                    <p>{item.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        </>)}

        {activeTab === 'billing' && (
    <div className="billing-history">
      <h2 className="section-title">Billing <span>History</span></h2>
       <div className="date-filter">
              <div className="date-filter-group">
                <label htmlFor="fromDate" className="date-filter-label">From</label>
                <input
                  type="date"
                  id="fromDate"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="date-filter-input"
                />
              </div>
              <div className="date-filter-group">
                <label htmlFor="toDate" className="date-filter-label">To</label>
                <input
                  type="date"
                  id="toDate"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className="date-filter-input"
                />
              </div>
              <button 
                onClick={handleDateFilter}
                className="date-filter-button"
              >
                Apply Filter
              </button>
            </div>
      {isLoading ? (
        <p>Loading billing history...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : billingHistory.length > 0 ? (
        <table className="billing-history-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Credits Received</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
          {filteredBillingHistory.map((transaction, index) => (
                    <tr key={index}>
                      <td>{new Date(transaction.payment_date).toLocaleDateString()}</td>
                      <td>{transaction.credits_received}</td>
                      <td>${transaction.amount.toFixed(2)}</td>
                    </tr>
                  ))}
          </tbody>
        </table>
      ) : (
        <p>No billing history available.</p>
      )}
    </div>
  )}
      </div>
      <footer className="footer">
        <p>MayaMaya Suite of Products - MayaMaya Lite</p>
      </footer>
      <Bot onLoad={handleBotLoad} />
    </div>
  );
}

const faqData = [
  {
    question: 'What are credits?',
    answer: 'Credits are a virtual currency that can be used to access premium features and unlock new opportunities within our platform. They provide a flexible way to pay for the services you need, without committing to a long-term subscription.'
  },
  {
    question: 'How do I purchase credits?',
    answer: 'To purchase credits, simply select the package that best fits your needs and click the "Buy Now" button. You\'ll be redirected to a secure Stripe checkout where you can complete your purchase using a credit card or other supported payment method.'
  },
  {
    question: 'Do credits expire?',
    answer: 'No, your credits do not expire. They are stored in your account and can be used at any time to access premium features and services.'
  },
  {
    question: 'What is your refund policy?',
    answer: 'We stand behind the quality of our services, but we understand that sometimes things don\'t work out as planned. If you\'re not satisfied with your purchase, please contact our support team within 14 days, and we\'ll be happy to provide a full refund.'
  }
];

export default Pricing;
