import Header from './Header';
import React, { useState, useEffect } from 'react';
import { FaTrash, FaEdit, FaEllipsisV, FaPlus, FaSearch, FaPlusCircle } from 'react-icons/fa';
import config from './config.json';
import Bot from './bot';
import { useNavigate } from 'react-router-dom';

export default function JobDescriptionPage({
  isModalOpen,
  isRegisterModalOpen,
  setIsRegisterModalOpen,
  setIsModalOpen,
  setIsLoggedIn,
  setUsername,
  setAccessKey,
  remainingCredits,
  setJobRole,
  name
}) {
  const [jobTitle, setJobTitle] = useState('');
  const [requiredSkills, setRequiredSkills] = useState([]);
  const [skillInput, setSkillInput] = useState('');
  const [degree, setDegree] = useState('');
  const [major, setMajor] = useState('');
  const [workExperience, setWorkExperience] = useState(0);
  const [jobResponsibilities, setJobResponsibilities] = useState('');
  const [certificates, setCertificates] = useState('');
  const [selectedJobDescription, setSelectedJobDescription] = useState('');
  const [savedJobDescriptions, setSavedJobDescriptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState('select');
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [editableJob, setEditableJob] = useState(null);
  const [deletedJobIds, setDeletedJobIds] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const itemsPerPage = 10;
  const storedEmail = localStorage.getItem("email");
  const storedAccessToken = localStorage.getItem("access_token");

  const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };

  useEffect(() => {
    if (storedEmail && storedAccessToken) {
      fetchJobRoles(storedEmail, storedAccessToken);
    }
  }, [storedAccessToken, storedEmail]);

  const convertMonthsToYears = (months) => {
    console.log(`months ${months}`);
    if (typeof months !== 'number') {
      console.error('Invalid input to convertMonthsToYears:', months);
      return "0+ years";
    }
    if (months < 12) return "0+ years";
    if (months < 36) return "1+ years";
    if (months < 60) return "3+ years";
    if (months < 120) return "5+ years";
    return "10+ years";
  };
  
  const yearsToMonths = (years) => {
    console.log(`years ${years}`);
    if (typeof years !== 'string') {
      console.error('Invalid input to YearsToMonths:', years);
      return "0+ years";
    }
    switch (years) {
     
      case "0+ years": return 0;
      case "1+ years": return 12;
      case "3+ years": return 36;
      case "5+ years": return 60;
      case "10+ years": return 120;
      default: return 0;
    }
  };

  const fetchJobRoles = async (storedEmail, storedAccessToken) => {
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/fetch-job-roles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({ UserID: storedEmail }),
      });

      const result = await response.json();
      if (response.status === 401 && result.message === "Token expired") {
        handleLogout();
      } else if (result.status === 200) {
        const jobTitles = result.data.map((job) => ({
          jobTitle: job.job_title,
          paragraph: job.job_description,
          job_id: job._id,
          job_responsibilities: job.job_responsibilities,
          job_skills: job.job_skills,
          job_degree: job.job_degree,
          job_major: job.job_major,
          job_work_ex: job.job_work_ex,
          job_certificates: job.job_certificates
        }));
        
        setSavedJobDescriptions(jobTitles);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error fetching job roles:', error);
    }
  };

  const openEditPopup = (job) => {
    const savedJob = savedJobDescriptions.find(savedJob => savedJob.job_id === job.job_id);
    console.log(savedJob.job_work_ex);
        
    setEditableJob({
      ...savedJob,
      job_degree: Array.isArray(savedJob.job_degree) ? savedJob.job_degree : [savedJob.job_degree],
      job_major: Array.isArray(savedJob.job_major) ? savedJob.job_major : [savedJob.job_major],
      job_responsibilities: Array.isArray(savedJob.job_responsibilities) ? savedJob.job_responsibilities : [savedJob.job_responsibilities],
      job_skills: Array.isArray(savedJob.job_skills) ? savedJob.job_skills : [savedJob.job_skills],
      job_work_ex: convertMonthsToYears(savedJob.job_work_ex)
    });
    setIsEditPopupOpen(true);
  };


  const closeEditPopup = () => {
    setIsEditPopupOpen(false);
    setEditableJob(null);
    setSkillInput('');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername(""); 
    setAccessKey("");
    localStorage.removeItem("access_token"); 
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles");
    localStorage.removeItem("driveLink");
    window.location.reload();
  };

  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHistoryData = async () => {
      const storedEmail = localStorage.getItem('email');
      const storedAccessToken = localStorage.getItem('access_token');

      if (!storedEmail || !storedAccessToken) {
        setError('You are not logged in. Please log in to access your results.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/retrieve-forensics-jobs-page`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedAccessToken}`
          },
          body: JSON.stringify({
            email: storedEmail,
            page_number: 1
          })
        });

        if (response.status === 401) {
          handleLogout(); // If token is expired or unauthorized, log out the user
          return;
        }

        const data = await response.json();

        if (response.ok) {
          setHistoryData(data.data.mayamaya_lite);
          //console.log(data.data.mayamaya_lite);
        } else {
          setError(data.message || 'Failed to fetch data.');
        }
      } catch (error) {
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchHistoryData();
  }, []);

  const triggerPopup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1);

  const handleSave = async () => {
    if (!jobTitle || !degree || !major || !workExperience || !jobResponsibilities) {
      triggerPopup('All fields are required!');
      return;
    }

    const formattedSkills = requiredSkills.map(skill => capitalizeFirstLetter(skill)).join(', ');
    const jobDescriptionParagraph = `${degree} in ${major}, with ${workExperience} of experience. Key responsibilities include ${jobResponsibilities}. Required skills: ${formattedSkills}. ${certificates ? `Certificates: ${certificates}.` : ''}`;

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/parse-job-role`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({
          UserID: storedEmail,
          Job: {
            job_title: jobTitle,
            job_description: jobDescriptionParagraph,
            job_responsibilities: jobResponsibilities,
            job_skills: requiredSkills,
            job_degree: degree,
            job_major: major,
            job_work_ex: workExperience,
            job_certificates: certificates
          },
        }),
      });

      const result = await response.json();
      if (result.status === 200) {
        setSavedJobDescriptions([...savedJobDescriptions, {
          jobTitle,
          paragraph: jobDescriptionParagraph,
          job_id: result.data._id,
          job_responsibilities: jobResponsibilities.split(', '),
          job_skills: requiredSkills,
          job_degree: degree,
          job_major: major,
          job_work_ex: workExperience,
          job_certificates: certificates
        }]);
        setJobTitle('');
        setRequiredSkills([]);
        setDegree('');
        setMajor('');
        setWorkExperience('');
        setJobResponsibilities('');
        setCertificates('');
        setSkillInput('');
        triggerPopup("Job Role saved successfully!")
      } else {
        console.error(result.message);
        triggerPopup("Failed to save Job Role. Please try again.");
      }
    } catch (error) {
      console.error('Error saving job description:', error);
      triggerPopup("An error occurred while saving the Job Role.");
    }
  };


  const handleAddSkillEdit = () => {
    if (skillInput && editableJob) {
      if (!editableJob.job_skills.includes(skillInput)) {
        setEditableJob({
          ...editableJob,
          job_skills: [...editableJob.job_skills, skillInput]
        });
      }
      setSkillInput('');
    }
  };

  const handleAddSkill = () => {
    if (skillInput && !requiredSkills.includes(skillInput)) {
      setRequiredSkills([...requiredSkills, skillInput]);
      setSkillInput('');
    }
  };

  const handleDeleteSkill = (skillToDelete) => {
    if (editableJob) {
      setEditableJob({
        ...editableJob,
        job_skills: editableJob.job_skills.filter(skill => skill !== skillToDelete)
      });
    } else {
      setRequiredSkills(requiredSkills.filter(skill => skill !== skillToDelete));
    }
  };



  const handleJobDescriptionSelect = (job) => {
    setSelectedJobDescription(selectedJobDescription?.jobTitle === job.jobTitle ? '' : job);
  };

  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleEllipsisClick = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleDelete = async (jobId) => {
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/delete-job-role`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${storedAccessToken}` },
        body: JSON.stringify({ job_id: jobId }),
      });
      const result = await response.json();
      if (response.ok) {
        setDeletedJobIds([...deletedJobIds, jobId]);
        setSavedJobDescriptions(savedJobDescriptions.filter(job => job.job_id !== jobId));
        setHistoryData(historyData.filter(entry => entry.jdm.job_id !== jobId));
        triggerPopup('Job role deleted successfully');
      } else {
        console.error('Failed to delete job:', result.message);
      }
      setActiveDropdown(null);
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  // Combine historyData and savedJobDescriptions
  const combinedData = [
    ...historyData.filter(historyEntry => 
      savedJobDescriptions.some(savedJob => savedJob.jobTitle === historyEntry.jdm.job_title)
    ),
    ...savedJobDescriptions.filter(job => 
      !historyData.some(historyEntry => historyEntry.jdm.job_title === job.jobTitle)
    )
  ];


  const handleEditJobDescription = async () => {
    if (!editableJob) return;

    const formattedSkills = editableJob.job_skills.map(skill => capitalizeFirstLetter(skill)).join(', ');
    const newDescription = `${editableJob.job_degree.join(', ')} in ${editableJob.job_major.join(', ')}, with ${editableJob.job_work_ex} of experience. Key responsibilities include ${editableJob.job_responsibilities.join(', ')}. Required skills: ${formattedSkills}. ${editableJob.job_certificates ? `Certificates: ${editableJob.job_certificates}.` : ''}`;

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/edit-job-description`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`
        },
        body: JSON.stringify({
          job_id: editableJob.job_id,
          new_description: newDescription,
          job_title: editableJob.jobTitle,
          job_responsibilities: editableJob.job_responsibilities.join(', '),
          job_skills: editableJob.job_skills,
          job_degree: editableJob.job_degree.join(', '),
          job_major: editableJob.job_major.join(', '),
          job_work_ex: yearsToMonths(editableJob.job_work_ex),
          job_certificates: editableJob.job_certificates
        })
      });

      const result = await response.json();
      if (response.ok) {
        setSavedJobDescriptions(savedJobDescriptions.map(job =>
          job.job_id === editableJob.job_id ? {
            ...job,
            jobTitle: editableJob.jobTitle,
            paragraph: newDescription,
            job_responsibilities: editableJob.job_responsibilities,
            job_skills: editableJob.job_skills,
            job_degree: editableJob.job_degree,
            job_major: editableJob.job_major,
            job_work_ex: yearsToMonths(editableJob.job_work_ex),
            job_certificates: editableJob.job_certificates
          } : job
        ));
        closeEditPopup();
        triggerPopup('Job description updated successfully');
      } else {
        console.error('Failed to edit job description:', result.message);
        triggerPopup('Failed to update job description');
      }
    } catch (error) {
      console.error('Error editing job description:', error);
    }
  };


  const handleSkillInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddSkill();
    }
  };

  const handleSkillInputKeyDownEdit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddSkillEdit();
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupMessage("");
  };

  const filteredJobs = savedJobDescriptions.filter(job => 
    job.jobTitle.toLowerCase().includes(searchTerm)
  );

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);

  const uniqueJobRolesCount = new Set(combinedData.map(entry => 
    'forensics' in entry ? entry.jdm.job_title : entry.jobTitle
  )).size;

  const filteredData = combinedData.filter(entry => {
    const jobTitle = 'forensics' in entry ? entry.jdm.job_title : entry.jobTitle;
    return jobTitle.toLowerCase().includes(searchQuery.toLowerCase());
  });

  // Recalculate pagination based on filtered data
  const totalFilteredItems = filteredData.length;
  const totalFilteredPages = Math.ceil(totalFilteredItems / itemsPerPage);
  const indexOfLastFilteredItem = currentPage * itemsPerPage;
  const indexOfFirstFilteredItem = indexOfLastFilteredItem - itemsPerPage;
  const currentFilteredItems = filteredData.slice(indexOfFirstFilteredItem, indexOfLastFilteredItem);


 const handlePageChange = (pageNumber) => {
   setCurrentPage(pageNumber);
 };


  if (!storedAccessToken) {
    return (
      <div>
        <Header
          setIsLoggedIn={setIsLoggedIn}
          setUsername={setUsername}
          setAccessKey={setAccessKey}
          remainingCredits={remainingCredits}
          setIsRegisterModalOpen={setIsRegisterModalOpen}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          isRegisterModalOpen={isRegisterModalOpen}
          name={name}
        />
        <div className="job-description-main">
          <p>You are not logged in. Please log in to access job roles.</p>
        </div>
        <footer className="footer"><p>MayaMaya Lite - A MayaMaya Product</p></footer>
      </div>
    );
  }

  return (
    <div>
      <Header
        setIsLoggedIn={setIsLoggedIn}
        setUsername={setUsername}
        setAccessKey={setAccessKey}
        remainingCredits={remainingCredits}
        setIsRegisterModalOpen={setIsRegisterModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        isRegisterModalOpen={isRegisterModalOpen}
        name={name}
      />

      {showPopup && (
        <div>
          <div className="overlay" onClick={handleClosePopup}></div>
          <div className="popup" style={{textAlign: "center"}}>
            <button className="close-btn" onClick={handleClosePopup}>×</button>
            {popupMessage}
          </div>
        </div>
      )}

      <div className="job-description-main">
      {activeTab === 'select' && (
      <div className="job-description-header1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <div>
    <h3 className="job-role-heading1">JOB ROLE</h3>
    <h3>Job Roles ({uniqueJobRolesCount})</h3>
    <p>Track and manage all job roles effortlessly in one place</p>
  </div>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '10px' }}>
    <button 
      className="create-new-button"
      onClick={() => setActiveTab('create')}
    >
      <FaPlus style={{ fontSize: '12px', marginRight: "10px" }} />
      Create New
    </button>
    <div className="search-container">
      <input
        type="text"
        placeholder="Search job roles..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
          padding: '8px',
          borderRadius: '4px',
          border: '0px solid #ccc',
        }}
      />
      <FaSearch style={{ color: '#888', marginLeft: '5px' }} />
    </div>
  </div>
</div>)}

{activeTab === 'create' && (
  <div className="job-description-header1" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
    <button 
      className="create-new-button"
      onClick={() => setActiveTab('select')}
    >Job Roles
    </button>
  </div>
)}

        
        {activeTab === 'select' && (
          <>
              
              <div className="historyPage-table-wrapper">
            <table className="historyPage-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>JOB TITLE</th>
                  <th>DESCRIPTION</th>
                  <th>CANDIDATES</th>
                  <th>SHORTLISTED</th>
                  <th>CREATED</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                  {currentFilteredItems.map((entry, index) => {
                    if (!deletedJobIds.includes(entry.job_id || entry.jdm?.job_id)) {
                      const isHistoryEntry = 'forensics' in entry;
                      return (
                      <tr key={isHistoryEntry ? entry.forensics._id : entry.job_id}>
                        <td>{index + 1}</td>
                        <td>{isHistoryEntry ? entry.jdm.job_title : entry.jobTitle}</td>
                        <td>
                          <span 
                            className="edit-icon" 
                            onClick={(e) => { 
                              e.stopPropagation(); 
                              openEditPopup(isHistoryEntry ? {
                                job_id: entry.jdm.job_id,
                                jobTitle: entry.jdm.job_title,
                              } : entry); 
                            }} 
                          >view</span>
                        </td>
                        <td>{isHistoryEntry ? entry.forensics.num_files : 0}</td>
                        <td>{isHistoryEntry ? entry.jdm.num_shortlisted : 0}</td>
                        <td>{isHistoryEntry 
                          ? new Date(entry.forensics.created_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
                          : (entry.created_date ? new Date(entry.created_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) : '')
                        }</td>
                        <td>
                          {isHistoryEntry && (
                            <button
                              style={{ border: "none", padding: "0px", cursor: "pointer", borderRadius: "4px", color: "#672024"}}
                              onClick={() => navigate(`/results?run_id=${entry.jdm.run_id}&folder_name=${entry.forensics.folder_name}&job_role=${entry.jdm.job_title}&load=false`)}
                            >
                              View Results →
                            </button>
                          )}
                        </td>
                        {/*<td>
                      <div className="action-dropdown">
                        <FaEllipsisV 
                          style={{fontSize: "20px", color: "#555", padding: "5px 5px", background: "#fff"}} 
                          className="action-icon" 
                          onClick={() => handleEllipsisClick(index)}
                        />
                        {activeDropdown === index && (
                          <div className="delete-confirm">
                            <p>Deleting this job role will remove all associated entries. Are you sure?</p>
                            <div>
                            <button onClick={() => handleDelete(entry.job_id || entry.jdm?.job_id)}>Yes, Delete</button>
                            <button onClick={() => setActiveDropdown(null)}>Cancel</button>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>*/}
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>

          {isEditPopupOpen && editableJob && (
      <div className="edit-popup-overlay" onClick={closeEditPopup}>
        <div className="edit-popup" onClick={(e) => e.stopPropagation()}>
          <button className="close-btn" onClick={closeEditPopup}>×</button>
          <h3>Edit Job Description</h3>
          <div className="job-form2">
            <div className="form-column">
              <label>Job Title:<span style={{color: "red"}}>*</span></label>
              <input
                type="text"
                value={editableJob.jobTitle}
                onChange={(e) => setEditableJob({...editableJob, jobTitle: e.target.value})}
                placeholder="Enter job title"
                required
              />

              <label>Degree:<span style={{color: "red"}}>*</span></label>
              <select
                value={editableJob.job_degree[0]}
                onChange={(e) => setEditableJob({...editableJob, job_degree: [e.target.value]})}
                className='options-popup-dropdown'
                style={{marginBottom: "10px"}}
                required
              >
                <option value="">Other</option>
                <option value="Bachelor's Degree">Bachelor's</option>
                <option value="Master's Degree">Master's</option>
                <option value="Doctoral Degree">PhD</option>
              </select>

              <label>Work Experience:<span style={{color: "red"}}>*</span></label>
              <select 
          value={editableJob.job_work_ex} 
          onChange={(e) => setEditableJob({...editableJob, job_work_ex: e.target.value})}
          className='options-popup-dropdown' 
          required
        >
          <option value="">Select Experience</option>
          <option value="0+ years">0+ years</option>
          <option value="1+ years">1+ years</option>
          <option value="3+ years">3+ years</option>
          <option value="5+ years">5+ years</option>
          <option value="10+ years">10+ years</option>
        </select>

              <label style={{marginTop: "30px"}}>Certifications (if any):</label>
              <input
                type="text"
                value={editableJob.job_certificates || ''}
                onChange={(e) => setEditableJob({...editableJob, job_certificates: e.target.value})}
                placeholder="Enter any relevant certifications"
              />
            </div>
            <div className="form-column">
              <label>Required Skills:<span style={{color: "red"}}>*</span></label>
              <div className="skills-container">
                <input
                  type="text"
                  value={skillInput}
                  onChange={(e) => setSkillInput(e.target.value)}
                  onKeyDown={handleSkillInputKeyDownEdit}
                  placeholder="Type a skill and press Enter to add"
                />
                {/*<button className="add-skill-btn" onClick={handleAddSkillEdit} style={{color: "#672024"}} >
                  <FaPlusCircle style={{ fontSize: '10px', color: '#672024', marginRight: "5px" }} />Add New Skill
                </button>*/}
                <div className="skills-box">
                  {editableJob.job_skills.map((skill, index) => (
                    <div key={index} className="skill-box">
                      {capitalizeFirstLetter(skill)} 
                      <button className="delete-skill-btn" onClick={() => setEditableJob({...editableJob, job_skills: editableJob.job_skills.filter(s => s !== skill)})}>x</button>
                    </div>
                  ))}
                </div>
              </div>

              <label>Major:<span style={{color: "red"}}>*</span></label>
              <input
                type="text"
                value={editableJob.job_major.join(', ')}
                onChange={(e) => setEditableJob({...editableJob, job_major: e.target.value.split(', ')})}
                placeholder="e.g., Computer Science, Psychology"
                required
              />

              <label>Job Responsibilities:<span style={{color: "red"}}>*</span></label>
              <textarea
                value={editableJob.job_responsibilities.join(', ')}
                onChange={(e) => setEditableJob({...editableJob, job_responsibilities: e.target.value.split(', ')})}
                placeholder="Enter your job responsibilities"
                required
              />
            </div>
          </div>
          <button className="save-btn" onClick={handleEditJobDescription}>Save Changes</button>
        </div>
      </div>
    )}

            <div className="pagination">
              {Array.from({ length: totalFilteredPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => handlePageChange(i + 1)}
                  className={`pagination-button ${currentPage === i + 1 ? 'active' : ''}`}
                >
                  {i + 1}
                </button>
              ))}
            </div>
</>
        )}

        {activeTab === 'create' && (
          <div className="job-description-container2">
            <h2>Create a New <span style={{color: "#672024"}}>Job Role</span></h2>
            <div className="job-form2">
              <div className="form-column">
                <label>Job Title:<span style={{color: "red"}}>*</span></label>
                <input
                  type="text"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  placeholder="Enter job title"
                  required
                />

              

                <label>Degree:<span style={{color: "red"}}>*</span></label>
                <select
                  value={degree}
                  onChange={(e) => setDegree(e.target.value)}
                  className='options-popup-dropdown'
                  style={{marginBottom: "10px"}}
                  required
                >
                  <option value="">Select Degree</option>
                  <option value="Bachelor's Degree">Bachelor's</option>
                  <option value="Master's Degree">Master's</option>
                  <option value="Doctoral Degree">PhD</option>
                </select>

              <label>Work Experience:<span style={{color: "red"}}>*</span></label>
                <select value={convertMonthsToYears(workExperience)} style={{marginBottom: "20px"}} onChange={(e) => setWorkExperience(yearsToMonths(e.target.value))} className='options-popup-dropdown' required>
                <option value="">Select Experience</option>
                <option value="0+ years">0+ years</option>
                <option value="1+ years">1+ years</option>
                <option value="3+ years">3+ years</option>
                <option value="5+ years">5+ years</option>
                <option value="10+ years">10+ years</option>
                </select>

                <label>Certifications (if any):</label>
                <input
                  type="text"
                  value={certificates}
                  onChange={(e) => setCertificates(e.target.value)}
                  placeholder="Enter any relevant certifications"
                />
              </div>
              <div className="form-column">

              <label>Required Skills:<span style={{color: "red"}}>*</span></label>
                <div className="skills-container">
                  <input
                    type="text"
                    value={skillInput}
                    onChange={(e) => setSkillInput(e.target.value)}
                    onKeyDown={handleSkillInputKeyDown}
                    placeholder="Type a skill and press Enter to add"
                  />
                  {/*<button className="add-skill-btn" onClick={handleAddSkill} style={{color: "#672024"}} ><FaPlusCircle style={{ fontSize: '10px', color: '#672024', marginRight: "5px" }} />Add New Skill</button>*/}
                  <div className="skills-box">
                    {requiredSkills.map((skill, index) => (
                      <div key={index} className="skill-box">
                        {capitalizeFirstLetter(skill)} 
                        <button className="delete-skill-btn" onClick={() => handleDeleteSkill(skill)}>x</button>
                      </div>
                    ))}
                  </div>
                </div>
                
              <label>Major:<span style={{color: "red"}}>*</span></label>
                <input
                  type="text"
                  value={major}
                  onChange={(e) => setMajor(e.target.value)}
                  placeholder="e.g., Computer Science, Psychology"
                  required
                />

                <label>Job Responsibilities:<span style={{color: "red"}}>*</span></label>
                <textarea
                  value={jobResponsibilities}
                  onChange={(e) => setJobResponsibilities(e.target.value)}
                  placeholder="Enter your job description"
                  required
                />


              </div>
            </div>
            <div className="button-container">
              <button style={{marginTop: "-50px"}} className="proceed-button" onClick={handleSave}>Save Job Role</button>
            </div>
          </div>
        )}
      </div>
      <footer className="footer"><p>MayaMaya Suite of Products - MayaMaya Lite</p></footer>
      <Bot onLoad={handleBotLoad} />
    </div>
  );
}