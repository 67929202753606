import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import config from './config.json';
import './PasswordReset.css';
import Bot from './bot';

const PasswordReset = () => {
  const [responseMessage, setResponseMessage] = useState('');
  const [loginMessage, setLoginMessage] = useState('');
  const navigate = useNavigate();

  const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };

  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
  const emailVerification = async () => {
    try {
      const response = await fetch(`${config.AUTH_BASE_URL}/api/user/verify-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
        }),
      });

      const data = await response.json();

      if (data.status === 200) {
        setResponseMessage(data.message);
        setLoginMessage('Your email has been successfully verified.');
      } else {
        setResponseMessage(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('Failed to verify email. Please try again later.');
    }
  };
  emailVerification();
}, []);

  const handleLogo = () => {
    navigate("/");
  }

  return (
    <div className="password-reset-container">
      {/* Header with Logo */}
      <header className="password-reset-header">
        <img src="/logo.svg" alt="Logo" className="logo" onClick={handleLogo} />
      </header>

      {/* Password Reset Form */}
      <div className="password-reset-form">
        <h2>Email <span style={{color: "#672024"}}>Verification</span></h2>
        

        {/* Show this message after a successful password reset */}
        {loginMessage && (
          <p className="login-message">
            Your email has been successfully verified.
          </p>
        )}
      </div>

      <footer className="footer">
        <p>MayaMaya Suite of Products - MayaMaya Lite</p>
      </footer>
      <Bot onLoad={handleBotLoad} />
    </div>
  );
};

export default PasswordReset;
