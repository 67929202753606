import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Modal.css';

const Modal = ({ isOpen, onClose, children }) => {
  const navigate = useNavigate();

  // Close modal and navigate to "/"
  const handleClose = () => {
    onClose(); // Call the onClose callback
    navigate('/'); // Navigate to "/"
  };

  if (!isOpen) return null;

  return (
    <div className="m1odal-overlay">
      <div className="m1odal-content">
        <button className="c1lose-button" onClick={handleClose}>×</button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
