import React, { useState, useEffect, useRef } from 'react';
import './JobDescriptionPage.css';
import Header from './Header';
import { Link, useNavigate } from "react-router-dom";
import config from './config.json';
import Bot from './bot';
import { FiSearch } from 'react-icons/fi';
import Modal from './Modal';
import { FaPlusCircle } from 'react-icons/fa';

const JobDescriptionPage = ({
  isRegisterModalOpen,
  setIsRegisterModalOpen,
  setIsLoggedIn,
  setUsername,
  setAccessKey,
  remainingCredits,
  setJobRole,
  name,
  setParseId
}) => {
  const [jobTitle, setJobTitle] = useState('');
  const [requiredSkills, setRequiredSkills] = useState([]);
  const [skillInput, setSkillInput] = useState('');
  const [degree, setDegree] = useState('');
  const [major, setMajor] = useState('');
  const [workExperience, setWorkExperience] = useState(0);
  const [jobResponsibilities, setJobResponsibilities] = useState('');
  const [certificates, setCertificates] = useState('');
  const [selectedJobDescription, setSelectedJobDescription] = useState('');
  const [savedJobDescriptions, setSavedJobDescriptions] = useState([]);
  const [activeTab, setActiveTab] = useState('create');
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [created, setCreated] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isListVisible, setIsListVisible] = useState(false);
  const searchInputRef = useRef(null);

  const steps = ["Select Job Role", "Upload Resumes", "Choose Validation"]

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleModalClose = () => setIsModalOpen(false);

  const storedEmail = localStorage.getItem("email");
  const storedAccessToken = localStorage.getItem("access_token");

  const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };

  // Fetch saved job descriptions when the component mounts
  useEffect(() => {

      // Fetch email and access token from local storage
  const storedEmail = localStorage.getItem("email");
  const storedAccessToken = localStorage.getItem("access_token");

    if (storedEmail && storedAccessToken) {
      fetchJobRoles(storedEmail, storedAccessToken);
    }
  }, [storedAccessToken, storedEmail]);

  const fetchJobRoles = async (storedEmail, storedAccessToken) => {
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/fetch-job-roles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({ UserID: storedEmail }),
      });
  
      const result = await response.json();
      if (response.status === 401 && result.message === "Token expired") {
        // If token is expired, log the user out
        handleLogout();
      } else if (result.status === 200) {
        // Map the result.data to extract job titles
        const jobTitles = result.data.map((job) => ({
          jobTitle: job.job_title,
          paragraph: job.job_description,  // Empty or placeholder for future description
          _id: job._id,
          job_responsibilities: job.job_responsibilities,
          job_skills: job.job_skills,
          job_degree: job.job_degree,
          job_major: job.job_major,
          job_work_ex: job.job_work_ex,
          job_certificates: job.job_certificates
        }));
        setSavedJobDescriptions(jobTitles);
        //console.log(jobTitles);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error fetching job roles:', error);
    }
  };

  // Trigger popup with a message
  const triggerPopup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };
  
  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername(""); // Clear username on logout
    setAccessKey(""); // Clear access key on logout
    localStorage.removeItem("access_token"); // Remove access token from localStorage
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles"); // Remove uploaded files from localStorage
    localStorage.removeItem("driveLink");
    window.location.reload();
  };

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const handleSave = async () => {
    if (!jobTitle || !degree || !major || !workExperience || !jobResponsibilities) {
      triggerPopup('All fields are required!');
      return;
    }
    
    const formattedSkills = requiredSkills.map(skill => capitalizeFirstLetter(skill)).join(', ');
    const jobDescriptionParagraph = `${degree} in ${major}, with ${workExperience} of experience. Key responsibilities include ${jobResponsibilities}. Required skills: ${formattedSkills}. ${certificates ? `Certificates: ${certificates}.` : ''}`;
  
    const jobDescription = {
      jobTitle,
      paragraph: jobDescriptionParagraph,
    };
  
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/parse-job-role`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({
          UserID: storedEmail,
          Job: {
            job_title: jobTitle,
            job_description: jobDescriptionParagraph,
            job_responsibilities: jobResponsibilities,
            job_skills: requiredSkills,
            job_degree: degree,
            job_major: major,
            job_work_ex: yearsToMonths(workExperience),
            job_certificates: certificates
          },
        }),
      });
  
      const result = await response.json();
      if (result.status === 200) {
        // Update saved job descriptions state
        setSavedJobDescriptions([...savedJobDescriptions, jobDescription]);
        // Clear the form
        setJobTitle('');
        setRequiredSkills([]);
        setDegree('');
        setMajor('');
        setWorkExperience('');
        setJobResponsibilities('');
        setCertificates('');
        //console.log('Job Description Saved:', jobDescription);
  
        // Call handleOptionsClick after saving
        setCreated(true);

        handleOptionsClick(); // This will proceed to the next step
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error saving job description:', error);
    }
  };

  useEffect(() => {

  if (created) {
    handleOptionsClick();
  }
}, [created]);
  

  const handleSkillInputKeyDown = (e) => {
    if (e.key === 'Enter') handleAddSkill();
  };

  const handleAddSkill = () => {
    if (skillInput && !requiredSkills.includes(skillInput)) {
      setRequiredSkills([...requiredSkills, skillInput]);
      setSkillInput(''); // Clear input after adding skill
    }
  };

  const handleDeleteSkill = (skillToDelete) => {
    setRequiredSkills(requiredSkills.filter(skill => skill !== skillToDelete));
  };

  const handleJobDescriptionSelect = (job) => {
    setSelectedJobDescription(job);
    setJobRole(job);
    setSearchTerm(job.jobTitle);
    setIsListVisible(false);
  };
  
  const handleOptionsClick = () => {
    if (selectedJobDescription || created) {
      window.scrollTo(0, 0);
      navigate("/upload");
    } else {
      triggerPopup("Please select a job description before proceeding.");
    }
  };


  const handleLogo = () => {
    navigate("/");
  }  

  const handleDeleteJobDescription = (jobTitleToDelete) => {
    setSavedJobDescriptions(savedJobDescriptions.filter(job => job.jobTitle !== jobTitleToDelete));
    if (selectedJobDescription === jobTitleToDelete) {
      setSelectedJobDescription('');
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupMessage("");
  };

  const handleCancelClick = () => {
    navigate("/");
  };

  const handleSearchInputFocus = () => {
    setIsListVisible(true);
  };

  const filteredJobDescriptions = savedJobDescriptions.filter(job =>
    job.jobTitle.toLowerCase().includes(searchTerm.toLowerCase())
  ); 

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedJobDescription(null);
    setIsListVisible(true);
  };

  if (!storedAccessToken) {
    return (
      <div>
        <Header
          setIsLoggedIn={setIsLoggedIn}
          setUsername={setUsername}
          setAccessKey={setAccessKey}
          remainingCredits={remainingCredits}
          setIsRegisterModalOpen={setIsRegisterModalOpen}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          isRegisterModalOpen={isRegisterModalOpen}
        />
        <div className="job-description-main">
          <p>You are not logged in. Please log in to proceed.</p>
        </div>
        <footer className="footer">
        <p>MayaMaya Lite - A MayaMaya Product</p>
      </footer>
      </div>
    );
  }

  const currentStep = 1;

  const convertMonthsToYears = (months) => {
    if (months < 12) return "0+ years";
    if (months < 36) return "1+ years";
    if (months < 60) return "3+ years";
    if (months < 120) return "5+ years";
    return "10+ years";
  };
  
  const yearsToMonths = (years) => {
    switch (years) {
      case "0+ years": return 0;
      case "1+ years": return 12;
      case "3+ years": return 36;
      case "5+ years": return 60;
      case "10+ years": return 120;
      default: return 0;
    }
  };

  return (
    <div>
      <Header
        setIsLoggedIn={setIsLoggedIn}
        setUsername={setUsername}
        setAccessKey={setAccessKey}
        remainingCredits={remainingCredits}
        setIsRegisterModalOpen={setIsRegisterModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        isRegisterModalOpen={isRegisterModalOpen}
        name={name}
      />
    <Modal isOpen={isModalOpen} onClose={handleModalClose}>
    <div style={{minHeight: "calc(100vh - 60px)"}}>
      <div className='stepper-header' ><p>SHORTLIST CANDIDATES</p></div>

      <div className="step-progress-bar">
        <div style={{display: "flex", justifyContent: "space-between", textAlign: "center"}}><p style={{width: "33.33%", color: "#fff"}}>1. Create/Choose Job Role</p><p style={{width: "33.33%", color: "#777"}}>2. Upload Resumes</p><p style={{width: "33.33%", color: "#777"}}>3. Create Folder</p></div>
        <div className="progress-line" style={{margin: '10px 0'}}>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#fff" }}></div>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#777" }}></div>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#777" }}></div>
        </div>
      </div>


      {showPopup && (
          <div>
            <div className="overlay" onClick={handleClosePopup}></div>
            <div className="popup" style={{textAlign: "center"}}>
              <button className="close-btn" onClick={handleClosePopup}>×</button>
              {popupMessage}
            </div>
          </div>
        )}

      <div className="job-description-main">
        <div className="tabs-container">
        <button
            className={activeTab === 'create' ? 'active-tab' : ''}
            onClick={() => setActiveTab('create')}
          >
            Create New
            <p className="create-role-text">
              Have a job role saved already?  <span className="create-role-btn" onClick={() => setActiveTab('select')}>Choose Existing</span>
            </p>
          </button>
          <button
            className={activeTab === 'select' ? 'active-tab' : ''}
            onClick={() => setActiveTab('select')}
          >
            Choose Existing
            <p className="create-role-text">
              Don't have a job role?  <span className="create-role-btn" onClick={() => setActiveTab('create')}>Create New</span>
            </p>
              
          </button>
          
        </div>

        {activeTab === 'select' && (
          <div style={{width: "100%"}}>
            <div className="job-description-container" ref={searchInputRef}>
              <input
                type="text"
                placeholder="Choose a job role..."
                value={searchTerm}
                onChange={handleSearchInputChange}
                onFocus={handleSearchInputFocus}
                className="search-input"
              /> <FiSearch className="search-icon" />
              {isListVisible && filteredJobDescriptions.length > 0 && (
                <div className="search-dropdown">
                  <ul className="job-list">
                    {filteredJobDescriptions.map((job, index) => (
                      <li
                        key={index}
                        onClick={() => handleJobDescriptionSelect(job)}
                        className={selectedJobDescription === job ? 'selected' : ''}
                      >
                        {job.jobTitle}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="jd-button-container" style={{marginTop: "20px"}}>
              <button className="jr-cancel-button" onClick={handleCancelClick}>
                Back
              </button>
              <button className="proceed-button" onClick={handleOptionsClick}>
                Next
              </button>
            </div>
          </div>
        )}


        {activeTab === 'create' && (
          <>
          <div className="job-description-container2">
            
            <div className="job-form2">
            <div className="form-column">
              <label>Job Title:<span style={{color: "red"}}>*</span></label>
              <input
                type="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                placeholder="Enter job title"
                required
              />

              <label>Degree:<span style={{color: "red"}}>*</span></label>
              <select
                  value={degree}
                  onChange={(e) => setDegree(e.target.value)}
                  className='options-popup-dropdown'
                  style={{marginBottom: "10px"}}
                  required
                >
                  <option value="">Select Degree</option>
                  <option value="Bachelor's Degree">Bachelor's</option>
                  <option value="Master's Degree">Master's</option>
                  <option value="Doctoral Degree">PhD</option>
                </select>

                <label>Work Experience:<span style={{color: "red"}}>*</span></label>
                <select value={workExperience} style={{marginBottom: "20px"}} onChange={(e) => setWorkExperience(e.target.value)} className='options-popup-dropdown' required>
                <option value="">Select Experience</option>
                <option value="0+ years">0+ years</option>
                <option value="1+ years">1+ years</option>
                <option value="3+ years">3+ years</option>
                <option value="5+ years">5+ years</option>
                <option value="10+ years">10+ years</option>
                </select>

              

              <label>Certifications (if any):</label>
              <input
                type="text"
                value={certificates}
                onChange={(e) => setCertificates(e.target.value)}
                placeholder="Enter any relevant certifications"
              />

              
              </div>

              <div className="form-column">
              <label>Required Skills:<span style={{color: "red"}}>*</span></label>
              <div className="skills-container">
                <input
                  type="text"
                  value={skillInput}
                  onChange={(e) => setSkillInput(e.target.value)}
                  onKeyDown={handleSkillInputKeyDown}
                  placeholder="Type a skill and press Enter to add"
                />
                {/*<button className="add-skill-btn" onClick={handleAddSkill} style={{color: "#672024"}} ><FaPlusCircle style={{ fontSize: '10px', color: '#672024', marginRight: "5px" }} />Add New Skill</button>*/}
                <div className="skills-box">
                  {requiredSkills.map((skill, index) => (
                    <div key={index} className="skill-box">
                      {capitalizeFirstLetter(skill)} 
                      <button className="delete-skill-btn" onClick={() => handleDeleteSkill(skill)}>x</button>
                    </div>
                  ))}
                </div>
              </div>

              <label>Major:<span style={{color: "red"}}>*</span></label>
              <input
                type="text"
                value={major}
                onChange={(e) => setMajor(e.target.value)}
                placeholder="e.g., Computer Science, Psychology"
                required
              />

              <label>Job Responsibilities:<span style={{color: "red"}}>*</span></label>
              <textarea
                value={jobResponsibilities}
                onChange={(e) => setJobResponsibilities(e.target.value)}
                placeholder="Enter you job description"
                required
              />

              
              </div>
              
              
            </div>
            <div className="jd-button-container3" style={{display: "flex", paddingBottom: "0px", justifyContent: "flex-end", width: "100%"}}>
          <button className="jr-cancel-button" onClick={handleCancelClick}>
                        Cancel
                      </button>
          <button className="proceed-button" onClick={handleSave}>Save and Next</button>
         </div>
          </div>
         
         </>
        )}
      </div>
      <Bot onLoad={handleBotLoad} />
    </div></Modal>
  </div>
  );
};

export default JobDescriptionPage;
