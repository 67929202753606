import React, { useEffect, useState, useCallback } from 'react';
import './ChooseForensics.css';
import config from './config';
import Bot from './bot';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Modal from './Modal';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

const ChooseForensics = ({
  uploadedFiles,
  isLoggedIn,
  username,
  accessKey,
  remainingCredits,
  setRemainingCredits,
  userId,
  setUploadedFiles,
  jobRole,
  setAccessKey,
  setUsername,
  setIsLoggedIn,
  setFileLength,
  folder,
  driveLink,
  parseId,
  name,
  setIsRegisterModalOpen,
  isRegisterModalOpen,
  fileLength
}) => {
  const [runId, setRunID] = useState(null);
  const [showForensicsModal, setShowForensicsModal] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [hasLoggedOut, setHasLoggedOut] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isParsingComplete, setIsParsingComplete] = useState(false);

  const navigate = useNavigate();

  const triggerPopup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  const handleBotLoad = (hubspot) => {
    // HubSpot chatbot loaded successfully
  };

  const checkCreditStatus = async (totalApiHits) => {
    try {
      const response = await fetch(`${config.AUTH_BASE_URL}/api/credit/check-status`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          product_name: "MAYAMAYA_LITE",
          total_api_hits: totalApiHits,
        }),
      });

      const creditData = await response.json();
      if (creditData.msg && creditData.msg.includes("Bad Authorization header") && !hasLoggedOut) {
        handleLogout();
        setHasLoggedOut(true);
      }
      if (response.ok && creditData.status === 200) {
        setRemainingCredits(creditData.data.credits);
        return creditData.data.credits;
      } else {
        triggerPopup(creditData.message || "Error checking credit status");
        return false;
      }
    } catch (error) {
      console.error("Error checking credit status:", error);
      return false;
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername("");
    setAccessKey("");
    localStorage.removeItem("access_token");
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles");
    localStorage.removeItem("driveLink");
    window.location.href = "/";
  };

  const handleForensicsChoice = async (applyForensics) => {
    setShowForensicsModal(false);
    setIsAnimating(true);

    const storedEmail = localStorage.getItem("email");
    const numFiles = uploadedFiles.length;
    if (fileLength < 1) {
      setFileLength(numFiles);
    }

    const availableCredits = await checkCreditStatus(numFiles);

    if (availableCredits < numFiles) {
      triggerPopup("Insufficient credits. Please recharge.");
      return;
    }

    startProgressAnimation(numFiles);

    const payload = {
      email: storedEmail,
      job_id: jobRole._id,
      folder_name: folder,
      forensics: true,
      jdm: true,
      user_id: userId
    };

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/jdm-forensics`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.data && data.data.run_id) {
          setRunID(data.data.run_id);
          console.log("Run ID set:", data.data.run_id);
        } else {
          console.error("Run ID not found in the response:", data);
        }
        if (data.data.parsing === false) {
          setFileLength(0);
        }
        setRemainingCredits(availableCredits - numFiles);
      } else {
        console.error("Error sending data to the backend.");
      }
    } catch (error) {
      console.error("Error in API request:", error);
    }
  };

  const startProgressAnimation = (numFiles) => {
    const totalDuration = numFiles * 8 * 1000;
    const interval = 100;
    const steps = (totalDuration * 0.9) / interval;

    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 90) {
          clearInterval(progressInterval);
          checkParseStatus();
          return 90;
        }
        return prevProgress + (90 / steps);
      });
    }, interval);
  };


  const checkParseStatus = useCallback(async () => {
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/folder/parse-status?parse_id=${parseId}`, {
        headers: {
          'Authorization': `Bearer ${accessKey}`,
        },
      });
      const data = await response.json();
      
      if (data.data.folder_parsing_status === 'completed') {
        setProgress(100);
        setIsParsingComplete(true);
      } else {
        setTimeout(checkParseStatus, 10000);
      }
    } catch (error) {
      console.error('Error checking parse status:', error);
      setTimeout(checkParseStatus, 10000);
    }
  }, [parseId, accessKey]);


  useEffect(() => {
    handleForensicsChoice(true);
  }, []);

  useEffect(() => {
    if (isParsingComplete && runId) {
      navigate(`/results?run_id=${runId}&folder_name=${folder}&job_role=${jobRole.jobTitle}&load=true`);
    } else if (isParsingComplete && !runId) {
      console.error("Run ID is not available. Cannot navigate to results.");
      triggerPopup("Error: Run ID not available. Please try again.");
    }
  }, [isParsingComplete, runId, navigate, folder, jobRole]);

  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleModalClose = () => setIsModalOpen(false);

  return (
    <div>
      <Header
        setIsLoggedIn={setIsLoggedIn}
        setUsername={setUsername}
        setAccessKey={setAccessKey}
        remainingCredits={remainingCredits}
        setIsRegisterModalOpen={setIsRegisterModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        isRegisterModalOpen={isRegisterModalOpen}
        name={name}
      />
    <Modal isOpen={isModalOpen} onClose={handleModalClose}>
    <div>
      <div className='stepper-header'><p>SHORTLIST CANDIDATES</p></div>

      <div className="step-progress-bar">
        <div style={{display: "flex", justifyContent: "space-between", textAlign: "center"}}>
          <p style={{width: "33.33%", color: "#777"}}>1. Create/Choose Job Role</p>
          <p style={{width: "33.33%", color: "#777"}}>2. Upload Resumes</p>
          <p style={{width: "33.33%", color: "#fff"}}>3. Create Folder</p>
        </div>
        <div className="progress-line" style={{margin: '10px 0'}}>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#777" }}></div>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#777" }}></div>
          <div className="step-progress-fill" style={{ width: "33.33%", backgroundColor: "#fff" }}></div>
        </div>
      </div>

      
      <div style={{ padding: '20px', display: "flex", flexDirection: "column", alignItems: "center", height: "100vh" }}>
        <div style={{ width: 150, height: 150 }}>
          <svg style={{ height: 0 }}>
            <defs>
              <linearGradient id="progressGradient" gradientTransform="rotate(73)">
                <stop offset="12.05%" stopColor="#030507" />
                <stop offset="88.83%" stopColor="#113e33" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgressbar
            value={progress}
            text={`${Math.round(progress)}%`}
            styles={buildStyles({
              pathColor: 'url(#progressGradient)',
              textColor: '#000',
              trailColor: '#d6d6d6',
              backgroundColor: '#f8f8f8',
              textSize: '18px',
              strokeLinecap: 'square',
            })}
            strokeWidth={20}
            diameter={200}
          />
        </div>
        <p className="progress-text" style={{ marginTop: 20, fontSize: "1rem" }}>Validating & Analyzing Resumes...</p>
        <p className="progress-text" style={{ marginTop: 10, fontSize: "0.9rem" }}>Our AI system is reviewing the uploaded resumes, assessing key details like work experience, education and required skills. This process will take a moment.</p>
      </div>
      
      <Bot onLoad={handleBotLoad} />
    </div>
    </Modal>
  </div>
  );
};

export default ChooseForensics;